import { defineStore } from 'pinia';
import { ProcurementAccessLevel, SettingsAccessLevels } from '../interfaces/procurementAccessLevelTypes.util';

export const loggedInUsersAccessLevelStore = defineStore({
    id: 'loggedInUsersAccessLevelStore',
    state:()=>({
        login_hint: "",
        settings_access_levels_persist: {
            view_access_level: false,
            manage_access_level: false,
            view_cap_approver: false,
            manage_cap_approver: false,
        },
        procurement_access_level_persist: {
            view_your_requisitions: false,
            manage_requisitions: false,
            view_staff_requisitions: false,
            manage_staff_requisitions: false,
            view_suppliers: false,
            manage_suppliers: false,
            view_procurement_manager: false,
            manage_procurement_manager: false,
            view_all_requests: false,
            manage_all_requests: false,
        },
    }),

    actions:{
        //set setting access levels
        setSettingsAccessLevels(data:SettingsAccessLevels){
            this.settings_access_levels_persist = data;
        },

        //set procurement access levels
        setProcurementAccessLevel(data:ProcurementAccessLevel){
            this.procurement_access_level_persist = data;
        },

        setLoginHint(data: string){this.login_hint = data;},
    },
    persist: true
});