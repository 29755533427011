import { defineStore } from 'pinia';
import { procurementPermissionsType } from '../interfaces/procurementPermissionsType';

export const useProcurementPermissionsStore = defineStore({
  id: 'procurementPermissions',
  state: () => ({
    permissions: {
      selected: 0,
      enable_all: false,
      view_supplier: false,
      manage_supplier: false,
      view_staff_Requisition: false,
      manage_staff_Requisition: false,
      view_cap_approver: false,
      manage_cap_approver: false,
      view_your_requisition:false,
      manage_your_requisition:false
    }
  }),
  actions: {
    setPermissions(procurementPermissions: procurementPermissionsType) {
      this.permissions.selected = procurementPermissions?.selected;
      this.permissions.enable_all = procurementPermissions?.enable_all;
      this.permissions.view_supplier = procurementPermissions?.view_supplier;
      this.permissions.manage_supplier =
        procurementPermissions?.manage_supplier;
      this.permissions.view_staff_Requisition =
        procurementPermissions?.view_staff_Requisition;
      this.permissions.manage_staff_Requisition =
        procurementPermissions?.manage_staff_Requisition;
      this.permissions.view_cap_approver =
        procurementPermissions?.view_cap_approver;
      this.permissions.manage_cap_approver =
        procurementPermissions?.manage_cap_approver;
        this.permissions.view_your_requisition = procurementPermissions?.view_your_requisition;
        this.permissions.manage_your_requisition = procurementPermissions?.manage_your_requisition;
    }
  },
  persist: true
});
