import { ProcurementAccessLevelState } from '../interfaces/procurementAccessLevelTypes.util';

export const sharedState = (): ProcurementAccessLevelState => ({
  basic_information: {
    id: 0,
    access_level_name: "",
    description: ""
  },
  settings_access_levels: {
    view_access_level: false,
    manage_access_level: false,
    view_cap_approver: false,
    manage_cap_approver: false,
  },
  procurement_access_level: {
    view_your_requisitions: false,
    manage_requisitions: false,
    view_staff_requisitions: false,
    manage_staff_requisitions: false,
    view_suppliers: false,
    manage_suppliers: false,
    view_procurement_manager: false,
    manage_procurement_manager: false,
    view_all_requests: false,
    manage_all_requests: false,
  },
  user_permission: {},
  assigned_users: [],
});
