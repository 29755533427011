// media-control.ts
import { ref, onBeforeUnmount, onMounted } from "vue";

const useMediaControl = () => {
  const isMobileScreen = ref<boolean>(false);
  const isTabletScreen = ref<boolean>(false);
  const isLaptopScreen = ref<boolean>(false);
  const isLargeScreen = ref<boolean>(false);

  const handleResize = () => {
    isMobileScreen.value = window.innerWidth < 767;
    isTabletScreen.value = window.innerWidth >= 768 && window.innerWidth <= 1023;
    isLaptopScreen.value = window.innerWidth >= 1024 && window.innerWidth <= 1439;
    isLargeScreen.value = window.innerWidth >= 1440;
  };

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
  });

  onMounted(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  });

  return {
    isMobileScreen,
    isTabletScreen,
    isLaptopScreen,
    isLargeScreen
  };
};

export default useMediaControl;