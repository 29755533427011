import { defineStore } from 'pinia';
import { AssignedUserAccessLevel, ProcurementAccessLevel, SettingsAccessLevels } from '../interfaces/procurementAccessLevelTypes.util';
import { sharedState } from './shared_access_level';

//Handle Viewing Only
export const useProcurementViewAccessLevelStore = defineStore({
  id: 'ViewProcurementAccessLevels',
  
  state: sharedState, 

  actions: {
    getSettingsAccessLevels(){
        return this.settings_access_levels;
    },
    getProcurementAccessLevels(){
        return this.procurement_access_level;
    },
    getBasicInformation(){
      return this.basic_information;
    },

    
    
    //set Basic Information
    setAccessLevelId(id:number){this.basic_information.id = id;},
    setAccessLevelName(input:string){ this.basic_information.access_level_name = input;},
    setAccessLevelDesc(input:string){this.basic_information.description = input;},

    //set Enable All Procurement Access
    setEnableAllProcurementAccess(status:boolean){
      for (const key in this.procurement_access_level) {
        this.procurement_access_level[key as keyof ProcurementAccessLevel] = status;
      }
    },
   
    //set Access for Requisitions
    setViewYourRequisitions(status: boolean){ this.procurement_access_level.view_your_requisitions = status;},
    setManageRequisitions(status: boolean){this.procurement_access_level.manage_requisitions = status;},
    setViewStaffRequisitions(status: boolean){this.procurement_access_level.view_staff_requisitions = status;},
    setManageStaffRequisitions(status: boolean){this.procurement_access_level.manage_staff_requisitions = status;},
  
    //Set Access for Suppliers
    setManageSuppliers(status: boolean){this.procurement_access_level.manage_suppliers = status;},
    setViewSuppliers(status:boolean){this.procurement_access_level.view_suppliers = status;},
    
    //set Access for procurement manager
    setViewProcurementManager(status:boolean){this.procurement_access_level.view_procurement_manager =  status;},
    setManageProcurementManager(status: boolean){this.procurement_access_level.manage_procurement_manager = status;},

      //Set access level for Approved request
    setViewAllRequests(status:boolean){this.procurement_access_level.view_all_requests =  status;},
    setManageAllRequests(status:boolean){this.procurement_access_level.manage_all_requests =  status;},
    
     //Manage Setting Access
      //set Enable All settings Access
     setEnableAllSettings(status:boolean){
      for (const key in this.settings_access_levels) {
        this.settings_access_levels[key as keyof SettingsAccessLevels] = status;
      }
    },

    //Set Settings Access
    setViewAccessLevel(status:boolean){this.settings_access_levels.view_access_level = status;},
    setManageAccessLevel(status:boolean){this.settings_access_levels.manage_access_level = status;},
    setViewCapApprover(status:boolean){this.settings_access_levels.view_cap_approver = status;},
    setManageCapApprover(status:boolean){this.settings_access_levels.manage_cap_approver = status;},

     //Set Assigned Users
    setAssignedUsers(users:AssignedUserAccessLevel[]){
      this.assigned_users = users;
    },
}, 
persist: true
});

